<template src="./ViewAllMyCourses.html"></template>

<script>
import OfferThumbnail from "@/components/OfferThumbnail/OfferThumbnail";
import EmployerOfferThumbnail from "@/components/EmployerOfferThumbnail/EmployerOfferThumbnail";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinHorizontalCardAcademic from "@/components/ArrobaMedellinHorizontalCardAcademic/ArrobaMedellinHorizontalCardAcademic";
import ArrobaMedellinHorizontalCardIdi from "@/components/ArrobaMedellinHorizontalCardIdi/ArrobaMedellinHorizontalCardIdi";
import createEditEvent from "@/components/Events/createEditEvent";
import showEventDetails from "@/components/Events/showEventDetails";
import { mapGetters } from "vuex";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import coursesService from "@/services/Courses";

export default {
  name: "ViewAllMyCourses",
  components: {
    OfferThumbnail,
    EmployerOfferThumbnail,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinHorizontalCardAcademic,
    ArrobaMedellinHorizontalCardIdi,
    createEditEvent,
    ArrobaMedellinArrobitaNoData,
    showEventDetails,
  },
  data() {
    return {
      title: "Mis cursos",
      component: "ArrobaMedellinHorizontalCardAcademic",
      data: [],
      background:
        "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela-test.appspot.com/o/background%2Fbackground-academico.png?alt=media&token=0b6a920a-f253-4234-ac42-b3dafac1ffbe",
      typeCard: { button: `button--purple`, progress: "is-info" },
      columnWidth: 6,
      isComponentModalActive: false,
      dataModalComponent: {},
      modalComponent: "",
      take: 15,
      skip: 0,
      hideButton: false,
    };
  },
  mounted() {
    this.getData();
    if (!this.data) this.$router.go(-1);
    this.$refs.background.style.background =
      "url(" + this.background + ") center no-repeat cover";
  },
  methods: {
    async getData() {
      try {
        this.data = await coursesService.getMyCourses(this.take, this.skip);
        if (this.data.length > 14) this.hideButton = true;
      } catch (error) {
        console.error(error);
      }
    },
    async getMoreElements() {
      if (this.data.length > 0) {
        this.skip += this.take;
      }
      const elements = await coursesService.getMyCourses(this.take, this.skip);
      if (elements <= 0) this.hideButton = false;
      this.data.push(...elements);
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./ViewAllMyCourses.scss"></style>
